import { Autocomplete } from "@mui/material";
import CountryApi from "apis/CountryApi";
import useDebouncedState from "hooks/useDebouncedState";
import { forwardRef, useState } from "react";
import { normalizeArray } from "utils/ObjectUtils";

const CountryAutocomplete = forwardRef(
  /**
   *
   * @param {CountryAutocompleteProps} props
   * @param {*} ref
   * @returns
   */
  function CountryAutocomplete(props, ref) {
    const { mapOptions, callbackProps, inputValueQueryParams, ...restProps } =
      props;

    const [q, setQ] = useState("");

    const inputValue = restProps.inputValue || q;

    const [debouncedInputValue] = useDebouncedState(inputValue, {
      wait: 200,
      enableReInitialize: true,
    });

    const countriesQueryResult = CountryApi.useGetCountriesQuery(
      { params: { _search: debouncedInputValue, ...inputValueQueryParams } }
      // { skip: !inputValue }
    );

    const valueCountriesQueryResult = CountryApi.useGetCountriesQuery(
      {
        params: {
          ...((idsOrCodes) => ({
            $or: [
              { id: idsOrCodes },
              { iso2: idsOrCodes },
              { iso3: idsOrCodes },
              { phonecode: idsOrCodes },
            ],
          }))(
            restProps.value
              ? JSON.stringify({
                  $in: Array.isArray(restProps.value)
                    ? restProps.value
                        ?.map((item) =>
                          typeof item === "object"
                            ? [item.id, item.iso2, item.iso3, item.phonecode]
                            : item
                        )
                        ?.flat()
                    : typeof restProps.value === "object"
                    ? [restProps.value?.id, restProps.value?.code]
                    : [restProps.value],
                })
              : undefined
          ),
        },
      },
      {
        skip: !(Array.isArray(restProps.value)
          ? restProps.value?.length
          : restProps.value),
      }
    );

    const allOptions = [
      ...(countriesQueryResult.data?.data ||
        countriesQueryResult.currentData?.data ||
        []),
      ...(valueCountriesQueryResult.data?.data ||
        valueCountriesQueryResult.currentData?.data ||
        []),
    ];

    const normalizedOptions = normalizeArray(allOptions);
    const normalizedIso2Options = normalizeArray(allOptions, {
      getKey: ({ iso2 }) => iso2,
    });

    const normalizedIso3Options = normalizeArray(allOptions, {
      getKey: ({ iso3 }) => iso3,
    });

    const normalizedPhoneCodeOptions = normalizeArray(allOptions, {
      getKey: ({ phonecode }) => phonecode,
    });

    const options = Object.values(normalizedOptions);

    function getOptionLabel(id) {
      return (
        id?.name ||
        normalizedOptions?.[id?.id || id]?.name ||
        normalizedIso2Options?.[id?.iso2 || id]?.name ||
        normalizedIso3Options?.[id?.iso3 || id]?.name ||
        normalizedPhoneCodeOptions?.[id?.phonecode || id]?.name ||
        id ||
        ""
      );
    }

    const isOptionEqualToValue = (option, value) => {
      return (
        option === value || option?.id === value?.id || option?.id === value
      );
    };

    const onInputChange =
      restProps.onInputChange || ((_, value) => setQ(value));

    return (
      <Autocomplete
        key={getOptionLabel(restProps?.value)}
        ref={ref}
        loading={countriesQueryResult.isFetching}
        freeSolo
        // options={mapOptions(options)}
        options={options}
        getOptionLabel={(option) => {
          return getOptionLabel(option);
        }}
        isOptionEqualToValue={isOptionEqualToValue}
        {...restProps}
        // value={
        //   // allOptions.length ? restProps?.value : restProps.multiple ? [] : {}
        //   typeof restProps.value === "string"
        //     ? { id: restProps.value }
        //     : restProps.value
        // }
        inputValue={inputValue}
        onInputChange={onInputChange}
        {...callbackProps?.({
          getOptionLabel,
          options,
          isOptionEqualToValue,
          normalizedOptions,
          normalizedIso2Options,
          normalizedIso3Options,
          normalizedPhoneCodeOptions,
        })}
      />
    );
  }
);

CountryAutocomplete.defaultProps = {
  mapOptions: (options) => options,
};

export default CountryAutocomplete;

/**
 * @typedef {{
 * inputValueQueryParams: any;
 * mapOptions: (options: any[]) => any[];
 * callbackProps: (props: CallbackProps) => import("@mui/material").AutocompleteProps;
 * } & import("@mui/material").AutocompleteProps} CountryAutocompleteProps
 */

/**
 * @typedef {{
 * normalizedOptions: Record<string, any>;
 * normalizedIso2Options: Record<string, any>;
 * normalizedIso3Options: Record<string, any>;
 * normalizedPhoneCodeOptions: Record<string, any>;
 * } & import("@mui/material").AutocompleteProps} CallbackProps
 */
